@import 'src/utils/utils';

.sign {
  margin-top: 15px;
  margin-bottom: 15px;

  @media(min-width: $md) {
    margin-right: 16px;
    margin-left: 16px;
  }

  @media(min-width: $lg) {
    margin-right: 0;
    margin-left: 0;
  }

  @media(min-width: $xxl) {
    @include box;
  }

  .suggestions-wrapper {
    @include Shadow-1;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    margin-top: 10px;
    border-radius: 8px;
    background-color: $Main-2;
    overflow: hidden;

    .suggestions-suggestion {
      @include Text-16-reg;
      padding-top: 10px;
      padding-right: 16px;
      padding-bottom: 10px;
      padding-left: 16px;
      transition: background-color 0.3s;
      cursor: pointer;

      &:hover {
        @media(min-width: $lg) {
          background-color: $Main-4;
        }
      }

      &:active {
        background-color: $Main-4;
      }

      strong {
        color: #ff0000;
      }
    }

    .suggestions-promo {
      display: none;
    }

    .suggestions-hint {
      display: none;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;

    @media(min-width: $xl) {
      display: grid;
      grid-template-columns: minmax(0, 1fr) 300px;
      gap: 40px;
    }

    @media(min-width: $xxl) {
      @include box;
    }
  }

  &__aside {

    .promo {
      position: sticky;
      top: 30px;
      margin: 0;
      padding-top: 30px;
      padding-bottom: 45px;

      @media(min-width: $lg) {
        padding: 25px;
      }
    }
  }

  &__content {
    width: 100%;
    max-width: 660px;
  }

  &__form {
    padding-top: 20px;
    padding-right: 16px;
    padding-bottom: 20px;
    padding-left: 16px;
    background-color: $Main-2;

    @media(min-width: $md) {
      padding-top: 35px;
      padding-right: 40px;
      padding-bottom: 35px;
      padding-left: 40px;
      border-radius: 20px;
    }
  }

  &__fields {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    border: 0;
    gap: 18px;

    @media(min-width: $md) {
      gap: 20px;
    }
  }

  &__field {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__field-inner {
    position: relative;
    display: flex;
    flex-direction: column;

    button+input {
      padding-right: 50px;
    }

    .validator__error {
      @include Text-12-reg;
      margin: 0;
    }
  }

  &__label {
    @include Text-12-reg;
    margin: 0;
  }

  &__eye {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 40px;
    height: 40px;
    margin: 0;
    padding: 0;
    border: 0;
    color: $Main-1;
    font-size: 0;
    line-height: 0;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    transition: color 0.3s;
    cursor: pointer;

    @media(min-width: $md) {
      top: 8px;
      right: 10px;
    }

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }

    svg {
      stroke: currentcolor;
      flex-shrink: 0;
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    gap: 15px;

    @media(min-width: $md) {
      flex-direction: row-reverse;
      gap: 25px;
    }

    .button {
      flex-grow: 1;
    }
  }

  &__footer {
    margin-top: 25px;
    padding-top: 20px;
    border-top: 1px solid $Main-5;
    text-align: center;

    @media(min-width: $md) {
      padding-top: 25px;
    }
  }

  &__link {
    @include Text-14-reg;
    text-decoration: none;
    color: $Main-1;
    transition: color 0.3s;

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }
  }

  &__title {
    @include Text-18-bold;
    margin: 0;
    margin-bottom: 15px;

    @media(min-width: $md) {
      margin-bottom: 25px;
    }

    &:not(:first-child) {
      margin-top: 25px;
      padding-top: 25px;
      border-top: 1px solid $Main-5;
    }
  }

  &__text {
    @include Text-16-reg;
    margin: 0;

    &:not(:first-child) {
      margin-top: 10px;
    }

    a {
      text-decoration: underline;
      color: $Corporate-2;
      text-decoration-color: transparent;
      transition: text-decoration-color 0.3s;

      &:hover {
        @media(min-width: $lg) {
          text-decoration-color: $Corporate-2;
        }
      }
    }
  }

  &__legal {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    gap: 15px;

    @media(min-width: $md) {
      margin-top: 20px;
      gap: 15px;
    }
  }

  &__disc {
    @include Text-12-reg;
    margin: 0;
    margin-top: 20px;
    padding-right: 16px;
    padding-left: 16px;
    color: $t-60;
    text-align: center;

    @media(min-width: $md) {
      padding: 0;
    }
  }
}